import React from 'react';
import styled from 'styled-components';
import SplashImg from '../../resources/images/beer/splash_overlay.png';
import SplashMobileImg from '../../resources/images/beer/splash_overlay_mobile.png';

const SplashOverlay = styled.div`
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 185px;
    display: block;
    background-image: url(${SplashMobileImg});
    background-repeat: repeat-x;
    background-size: cover;
    background-position: 0 100%;

    @media(min-width: 576px) {
      background-size: contain;
      background-image: url(${SplashImg});
    }

`;

const OverlaySplash = () => {
  return (<>
      <SplashOverlay/>
    </>
  )
};

export default OverlaySplash;
