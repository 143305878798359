import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import CarouselArrowUp from '../../resources/images/brewery/carousel-arrow-up.svg';
import CarouselArrowDown from '../../resources/images/brewery/carousel-arrow-down.svg';
import RightArrow from '../../resources/images/ui/carousel-right-arrow.svg';
import LeftArrow from '../../resources/images/ui/carousel-left-arrow.svg';
import posed from 'react-pose';
import Slider from 'react-slick/lib'
import Img from "gatsby-image"


const CarouselContainer = styled(Container)`
    position: relative;
    @media(max-width: 767px) {
        box-sizing: border-box;
        text-align: center;
    }
`;

const MobileCarouselArrows = styled.div`
    display: none;
    @media(max-width: 767px) {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        text-align: center;
    }
`;
const MobileLeftArrowStyled = styled.img`
    display: none;
    @media(max-width: 767px) {
        display: block;
        width: 40px;
        padding-right: 15px;
    }
`;
const MobileRightArrowStyled = styled.img`
    display: none;
    @media(max-width: 767px) {
        display: block;
        width: 40px;
        padding-left: 15px;
    }
    
`;
const MobileArrowSlideLeft = styled.button`
    display: none;
    @media(max-width: 767px) {
        display: block;
        cursor: pointer;
        background: transparent;
        border: none;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
    }
`;
const MobileArrowSlideRight = styled.button`
    display: none;
    @media(max-width: 767px) {
        display: block;
        cursor: pointer;
        background: transparent;
        border: none;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
    }
`;

const CarouselArrows = styled.div`
    position: absolute;
    top: calc(50% - 52.5px);
    left: -75px;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media(min-width: 998px) and (max-width: 1279px){
        top: 50%;
        left: -8%;
        transform: translateY(-50%);
    }
    @media(min-width: 768px) and (max-width: 997px){
        top: 50%;
        left: -10%;
        transform: translateY(-50%);
    }
    @media(max-width: 767px) {
       right: auto;
       left: auto;
       display: none;
    }
`;
const LeftArrowStyled = styled.img`
    height: 40px;
    margin-left: 10px;
    margin-top: 15px;
`;
const RightArrowStyled = styled.img`
    height: 40px;
`;
const ArrowSlideLeft = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
    @media(max-width: 767px) {
        margin-top: 0px;
        margin-right: 20px;
    }
`;
const ArrowSlideRight = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    @media(max-width: 767px) {
        margin-left: 20px;
    }
`;
const CarouselImg = styled(Img)`
    width: 85% !important;
    max-width: 553px;
    margin: auto;
    position: relative;
    z-index: 1;
    outline: none;
    img {
      object-fit: ${props => props.objectFit} !important;
    }
`;

const StyledSlider = styled(Slider)`
    width: 100%;
    max-width: 553px;
    .slick-slide {
        opacity: 0;
    }
    .slick-current {
        opacity: 1;
    }
`

const HoverZoom = posed.div({
    hoverable: true,
    focusable: true,
    pressable: true,
    init: {
        scale: 1,
    },
    hover: {
        scale: 1.1,
    },
    press: {
        scale: 1.1,
    },
    focus: {
        scale: 1.1,
    }
});

class ProductImageCarousel extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      }
      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }

    render() {
        const { images, altText} = this.props
        const settings = {
            dots: false,
            infinite: true,
            speed: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            vertical: false,
            verticalSwiping: false,
            swipeToSlide: false,
            adaptiveHeight: true,
            responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    vertical: false,
                    verticalSwiping: false,
                    swipeToSlide: false,
                  }
                },
            ]
          };
        return (
            <CarouselContainer>
                <HoverZoom>
                    <StyledSlider {...settings} ref={c => (this.slider = c)} >
                        {images.map((image, index) => {
                            return (
                                <CarouselImg fluid={image.localFile.childImageSharp.fluid} key={index} alt={altText} objectFit={image.localFile.publicURL.includes("png") ? "contain" : "cover"}/>
                            )
                        })}
                    </StyledSlider>
                </HoverZoom>
                <CarouselArrows >
                    <ArrowSlideRight onClick={() => this.previous()}><RightArrowStyled src={CarouselArrowUp} /></ArrowSlideRight>
                    <ArrowSlideLeft onClick={() => this.next()}><LeftArrowStyled src={CarouselArrowDown} /></ArrowSlideLeft>
                </CarouselArrows>
                <MobileCarouselArrows>
                    <MobileArrowSlideLeft onClick={() => this.next()}><MobileLeftArrowStyled src={LeftArrow} /></MobileArrowSlideLeft>
                    <MobileArrowSlideRight onClick={() => this.previous()}><MobileRightArrowStyled src={RightArrow} /></MobileArrowSlideRight>
                </MobileCarouselArrows>
            </CarouselContainer>);
    }
}

export default ProductImageCarousel;
