import React, { Component } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { isCartUpdating, isCartUpdated } from "../../store/reducers/index"
import { addItemAction } from "../../store/actions/cart/index"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

const AddToCartBtn = styled.button`
    cursor: ${props => props.disabled ? 'initial' : 'pointer'};
    text-decoration: none;
    background: ${props => props.isAdded ? colors.navy : 'transparent'};
    text-transform: uppercase;
    font-size: 12px;
    ${fonts.gilroyBold};
    border: 1px solid ${props => props.isAdded ? colors.navy : colors.mint};
    height: 44px;
    width: 200px;
    text-align: center;
    color: ${props => props.disabled || props.isAdded ? '#D1D1D1' : colors.navy};
    margin-left: 12px;
    letter-spacing: 2.77px;
    transition: background .3s,color .3s;
    -webkit-transition: background .3s,color .3s;
    position: relative;
    &:hover {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background-color: ${props => props.isAdded ? colors.navy : colors.mint};
        color: ${colors.white};
    }
    &:active, &:focus {
        outline: none;
    }
`;

const BorderStyled = styled.div`
    border-bottom: 5px solid ${colors.mint};
    border-right: 5px solid ${colors.mint};
    width: 192px;
    height: 39px;
    right: -5px;
    bottom: -5px;
    position: absolute;
    display: ${props => props.isAdded ? 'none' : props.isVisible ? 'block' : 'none'};
`;
class AddToCart extends Component {
  addToCart = (variantImage, e) => {
    e.preventDefault()

    const { isUpdating, variant, quantity } = this.props
    if (isUpdating) {
      return
    }
    this.props.addItemAction(variant, quantity, variantImage)
  }

  render() {
    const { images, isAdded, toggleHover, soldout } = this.props
    const { addToCart } = this
    // @todo dynamic variant image based on selected options
    let variantImage

    if (images.length > 0) {
      const firstImage = images[0]

      if (firstImage.localFile) {
        variantImage = firstImage.localFile.url
      }
    }

    return (
      <AddToCartBtn
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onClick={(e) => {
          addToCart(variantImage,e)
        }}
        isAdded={isAdded}
        disabled={soldout ? 1 : 0}
      >
        {soldout ? "Sold Out" : isAdded ? "Good catch!" : "Add to net"}
        <BorderStyled
          isVisible={!isAdded}
          isAdded={isAdded}
        />
      </AddToCartBtn>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAdding: isCartUpdating(state),
    isAdded: isCartUpdated(state),
  }
}

const mapDispatchToProps = {
  addItemAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart)
