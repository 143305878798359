import React from 'react';
import styled from 'styled-components';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import Hero from '../components/product/Hero';
import WhiteBlock from '../components/product/WhiteBlock';


const LayoutStyled = styled(Layout)`
  margin: 0px;
`;

const product = (props) => {
  const path = props.location.pathName
  const {product} = props.data
  const {products} = props.data.collections
  const filteredProducts = products.filter((item) => item.title !== product.title)
  let heroImage;
  let productGallery = []

  if (product.images.length > 0) {
    product.images.forEach((image) => {
      const imageTitle = image.localFile.name.toLowerCase()
      if (imageTitle.includes("hero")) {
        heroImage = image.localFile.childImageSharp.fluid
      }
      if (!imageTitle.includes("hero")) {
        productGallery.push(image)
      }
    })
  } else {
    heroImage = null
  }

  return (
    <LayoutStyled>
    <SEO title={product.title}
      description={product.description}
      fullTitle={true}
      path={path} />
    <Hero title={product.title} type={product.productType} hero={heroImage}/>
    <WhiteBlock product={product} filteredProducts={filteredProducts} productGallery={productGallery}/>
  </LayoutStyled>
  )
}

export default product

export const pageQuery = graphql`
    query ProductTemplateQuery($slug: String!) {
        collections: shopifyCollection {
          products {
            title
            productType
            handle
            tags
            priceRange {
              minVariantPrice {
                  amount
                  currencyCode
              }
            }
            images {
              localFile {
                url
              }
            }
          }
        }
        product: shopifyProduct(handle: {eq: $slug}) {
            id
            shopifyId
            handle
            title
            descriptionHtml
            description
            productType
            tags
            variants {
                id
                price
                shopifyId
                image {
                    id
                    localFile {
                        extension
                    }
                }
                title
                availableForSale
                selectedOptions {
                    name
                    value
                }
            }
            options {
                id
                values
                name
                shopifyId
            }
            priceRange {
                minVariantPrice {
                    amount
                    currencyCode
                }
            }
            images {
                id
                localFile {
                  url
                  name
                  publicURL
                  childImageSharp {
                  fluid(maxWidth: 2000, maxHeight: 2000, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
        }
    }
`


