import React, { Component } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid"
import ProductCard from "../core/ProductCard"
import TitleLetterImg1 from "../../resources/images/product/decoration-title-1.svg"
import TitleLetterImg2 from "../../resources/images/product/decoration-title-2.svg"
import TitleLetterImg3 from "../../resources/images/product/decoration-title-3.svg"
import TitleLetterImg4 from "../../resources/images/product/decoration-title-4.svg"
import TitleLetterImg5 from "../../resources/images/product/decoration-title-5.svg"
import TitleLetterImg6 from "../../resources/images/product/decoration-title-6.svg"
import TitleLetterImg7 from "../../resources/images/product/decoration-title-7.svg"
import TitleLetterImg8 from "../../resources/images/product/decoration-title-8.svg"
import TitleLetterImg9 from "../../resources/images/product/decoration-title-9.svg"
import FreeShippingImg from "../../resources/images/product/free-shipping-watermark.svg"
import ImageCarousel from "../core/ProductImageCarousel"
import BulletImg from "../../resources/images/product/bullet-line.svg"
import LineImg from "../../resources/images/beer/broken-line.svg"
import { Fade, Zoom } from "react-reveal"
import posed from "react-pose"
import colors from "../../styles/colors"
import {getOptionsData,getSelectedVariant} from "../../services/product/options"
import AddToCart from "./AddToCart"
import get from "lodash/get"
import { getLocalisedPrice } from "../../services/product/price"
import Img from "gatsby-image"


const WhiteBlockContainer = styled.div`
  width: 100%;
  background: ${colors.white};
  position: relative;
  padding-left: 0;
  padding-right: 0;
`
const ContentContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-bottom: 50px;
    padding-top: 40px;
  }
`
const RowStyled = styled(Row)`
  padding-bottom: 40px;
  padding-top: ${props => (props.filterRow ? "10px" : "40px")};
  margin-left: 0px;
  margin-right: 0px;
  max-width: ${props => (props.filterRow ? "500px" : "")};
  margin: auto;
  justify-content: center;
`
const ColStyled = styled(Col)`
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  text-align: ${props => (props.center ? "center" : "")};
  @media (max-width: 768px) {
    order: ${props => (props.controlled0 ? "0" : "")};
    order: ${props => (props.controlled1 ? "1" : "")};
  }
  @media (min-width: 992px) {
    padding-top: ${props => (props.middle === true ? "120px" : "0px")};
  }
  padding-bottom: ${props => (props.productCol ? "45px" : "")};
`
const Description = styled.div`
  font-size: 15px;
  line-height: 21px;
  ${fonts.gilroyRegular};
  color: #222e3c;
  text-align: left;
  max-width: 364px;

  p {
    padding-bottom: 10px;
    :last-of-type {
      padding-bottom: 0;
    }
    strong {
      ${fonts.gilroyBold};
    }

    b {
      ${fonts.gilroyMedium};
    }
    i {
      font-style: italic;
    }
  }

  ul {
    list-style-image: url(${BulletImg});
    list-style-position: inside;
    padding-top: 25px;
    li {
      font-size: 15px;
      ${fonts.gilroyRegular};
      line-height: 21px;
      color: #222e3c;
    }
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    p {
      padding-bottom: 8px;
      :last-of-type {
        padding-bottom: 0;
      }
    }
  }
`
const DecorationTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  @media (min-width: 360px) and (max-width: 385px) {
    margin-left: -5px;
  }
  @media (max-width: 325px) {
    margin-left: -5px;
  }
  overflow: hidden;
  padding-bottom: 50px;
`
const TitleLetter = styled.img`
  @media (max-width: 360px) {
    height: ${props => (props.small ? "12px !important" : "22px !important")};
  }
  @media (max-width: 768px) {
    height: ${props => (props.small ? "14px" : "26px")};
  }

  @media (min-width: 768px) and (max-width: 1128px) {
    height: ${props => (props.small ? "25px" : "48px")};
  }
  @media (min-width: 1128px) {
    height: ${props => (props.small ? "40px" : "79px")};
  }
  padding-bottom: ${props => (props.small ? "2%" : "")};
`
const SelectedContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-bottom: 50px;
    padding-top: 50px;
  }
`
const FreeShipping = styled.img`
  height: 121px;
  position: absolute;
  top: 75px;
  right: 20%;
  z-index: 11;
  @media (max-width: 768px) {
    top: 15px;
  }
`
const Price = styled.div`
  ${fonts.swissBlackExtended};
  color: #222e3c;
  font-size: 15px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 30px;
  padding-top: 85px;
  letter-spacing: 1.5px;
  @media (max-width: 768px) {
    padding-top: 40px;
    text-align: center;
  }
`

const GreyLine = styled.img`
  position: relative;
  z-index: 0;
  margin-left: -25%;
  padding-bottom: 30px;
  padding-top: 0px;
  @media (max-width: 768px) {
    display: none;
  }
`
const InputStyled = styled.input`
    cursor: pointer;
    height: 44px;
    min-width: 58px;
    border-radius: 0;
    border: ${props =>
      props.checked === true ? "3px solid #222E3C" : "1px solid #D1D1D1"};
    background: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    position: relative;
    :before {
        content: "${props => props.value}";
        text-transform: uppercase;
        ${fonts.gilroyBold};
        line-height: 12px;
        font-size: 12px;
        letter-spacing: 2.77px;
        color: "#222E3C";
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 6px);
        width: 100%;
    }
    @media(max-width: 768px) {
        min-width: 75px;
    }
`
const FormStyled = styled.form`
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    text-align: center;
    justify-content: center;
  }
`
const CountContainer = styled.div`
  width: 128px;
  height: 42px;
  border: 1px solid #d1d1d1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`
const AddItemButton = styled.button`
  cursor: ${props => (props.disabled ? "initial !important" : "pointer")};
  text-decoration: none;
  border: none;
  background: transparent;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 20px;
  color: ${props => (props.disabled ? "#D1D1D1" : colors.navy)};
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }
`
const ItemCount = styled.div`
  color: #222e3c;
  ${fonts.gilroyBold};
  letter-spacing: 2.77px;
  font-size: 12px;
  line-height: 12px;
`
const AddItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  @media (max-width: 768px) {
    text-align: center;
    justify-content: center;
  }
`

const HoverZoom = posed.div({
  hoverable: true,
  focusable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.1,
  },
  press: {
    scale: 1.1,
  },
  focus: {
    scale: 1.1,
  },
})

const Radio = styled.label`
    text-transform: uppercase;
    ${fonts.gilroyBold};
    line-height: 12px;
    font-size: 12px;
    letter-spacing: 2.77px;
    color: "#222E3C";
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 44px;
    min-width: 75px;
    padding: 10px;
    border: ${props =>
      props.checked === true ? "3px solid #222E3C" : "1px solid #D1D1D1"};
    box-sizing: border-box;
    margin-right: 8px;
    cursor: pointer;
    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    @media(min-width: 768px) {
        min-width: 58px;
    }

`

class WhiteBlock extends Component {
  state = {
    isBorder: false,
    item: 1,
    isAddedToNet: false,
    options: {},
    variant: null,
  }
  getProduct() {
    return get(this, "props.product")
  }

  handleChange = (optionName, event) => {
    const product = this.getProduct()

    let options = this.state.options

    options[optionName] = event.target.value

    this.setState({
      options: options,
      variant: getSelectedVariant(product, options),
    })
  }

  addItem = props => {
    if ((this.state.item < 1 || this.state.item === 1) && props === -1) {
      this.setState({
        item: 1,
      })
    } else {
      this.setState({
        item: this.state.item + props,
      })
    }
  }

  componentDidMount() {
    this.toggleHover()
    const product = this.getProduct()
    const optionsData = getOptionsData(product)

    this.setState({
      options: optionsData,
      variant: getSelectedVariant(product, optionsData),
    })
  }

  toggleHover = () => {
    this.setState({
      isBorder: !this.state.isBorder,
    })
  }

  render() {
    const { variant } = this.state
    const { product } = this.props
    const {
      title,
      priceRange: {
        minVariantPrice: { amount: price, currencyCode },
      },
      descriptionHtml,
      options,
      tags
    } = product
    const { filteredProducts: products, productGallery } = this.props
    let randomSelection = products.sort(() => 0.5 - Math.random()).slice(0, 4) 
    let isSoldOut = tags.find((tag) => tag.toLowerCase().includes("soldout")) || false;

    return (
      <WhiteBlockContainer>
        <FreeShipping src={FreeShippingImg} />
        <ContentContainer>
          <RowStyled>
            <ColStyled md={6}>
              {" "}
              <ImageCarousel images={productGallery} altText={title} />
            </ColStyled>
            <ColStyled md={6}>
              <Price>{variant ? getLocalisedPrice(variant.price, currencyCode, false) : getLocalisedPrice(price, currencyCode, false)}</Price>
              <GreyLine src={LineImg} />
              <Description
                dangerouslySetInnerHTML={{ __html: descriptionHtml }}
              ></Description>
              {options.map(option => {
                const { name: variantTitle, values: optionValues } = option
                return (
                  optionValues.length !== 1 && (
                    <FormStyled>
                      {optionValues.map((value, index) => {
                        return (
                          <HoverZoom key={index}>

                            <Radio checked={this.state.options[variantTitle] === value}>
                                <input
                                    type="radio"
                                    value={value}
                                    checked={this.state.options[variantTitle] === value}
                                    onChange={e => this.handleChange(variantTitle, e)} />
                                <span className="checkmark"></span>
                                {value}
                            </Radio>
                          </HoverZoom>
                        )
                      })}
                    </FormStyled>
                  )
                )
              })}
              <AddItemContainer>
                <CountContainer>
                  <AddItemButton
                    onClick={() => {
                      this.addItem(-1)
                    }}
                    disabled={this.state.item <= 1}
                  >
                    -
                  </AddItemButton>
                  <ItemCount> {this.state.item}</ItemCount>
                  <AddItemButton
                    onClick={() => {
                      this.addItem(1)
                    }}
                  >
                    +
                  </AddItemButton>
                </CountContainer>
                <AddToCart
                  {...product}
                  toggleHover={this.toggleHover}
                  options={this.state.options}
                  quantity={this.state.item}
                  variant={variant}
                  soldout={isSoldOut}
                />
              </AddItemContainer>
            </ColStyled>
          </RowStyled>
        </ContentContainer>
        <DecorationTitle>
          <Zoom cascade duration={1500}>
            <TitleLetter src={TitleLetterImg1} />
            <TitleLetter src={TitleLetterImg2} />
            <TitleLetter src={TitleLetterImg3} />
            <TitleLetter src={TitleLetterImg4} />
            <TitleLetter src={TitleLetterImg5} />
            <TitleLetter src={TitleLetterImg6} small />
            <TitleLetter src={TitleLetterImg7} />
            <TitleLetter src={TitleLetterImg8} />
            <TitleLetter src={TitleLetterImg4} />
            <TitleLetter src={TitleLetterImg1} />
            <TitleLetter src={TitleLetterImg8} />
            <TitleLetter src={TitleLetterImg9} />
          </Zoom>
        </DecorationTitle>
        <SelectedContainer>
          <RowStyled>
            {randomSelection.map((product, index) => {
              return (
                <ColStyled sm={12} md={6} lg={3} key={index} productCol>
                  <Fade duration={500} delay={index * 250}>
                    <ProductCard product={product} selected />
                  </Fade>
                </ColStyled>
              )
            })}
          </RowStyled>
        </SelectedContainer>
      </WhiteBlockContainer>
    )
  }
}

export default WhiteBlock
