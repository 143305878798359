import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import BackgroundImage from "gatsby-background-image"
import { graphql, StaticQuery } from "gatsby"
import OverlaySplash from "../beersPDP/OverlaySplash"
import BlueLineImg from "../../resources/images/brewery/blue-line.svg"
import { Fade } from "react-reveal"

const BackgroundStyled = styled.div`
  position: relative;
  height: 580px;
  margin-top: -138px;
  text-align: center;
  @media (max-width: 768px) {
    height: 420px;
  }
`
const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100vw;
  height: 100%;
  background-size: cover;
`

const VerticalAlign = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BlueLine = styled.img`
  padding-top: 25px;
`

const Title = styled.h2`
  ${fonts.swissBlackExtended};
  color: ${colors.white};
  text-transform: uppercase;
  max-width: 650px;
  font-size: 42px;
  letter-spacing: 59.52;
  line-height: 50px;
  margin-top: 0;
  @media (max-width: 768px) {
    font-size: 24px;
    letter-spacing: 62.5;
    line-height: 29px;
    max-width: 349px;
    margin-top: 24px;
  }
`

const HomeHero = ({ title, type, hero }) => (
  <StaticQuery
    query={graphql`
      query {
        itemBackground: file(
          relativePath: { eq: "product/hero-background.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        beerBackground: file(
          relativePath: { eq: "product/beer-background.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const productType = type.toLowerCase()
      const imageData =
        productType === "beer"
          ? data.beerBackground.childImageSharp.fluid
          : data.beerBackground.childImageSharp.fluid
      return (
        <BackgroundStyled>
          <BackgroundImageStyled
            fluid={hero ? hero : imageData}
            critical={true}
            durationFadeIn={100}
          >
            <VerticalAlign>
              <Fade duration={500} delay={250}>
                <Title>{title}</Title>
              </Fade>
              <Fade duration={500} delay={500}>
                <BlueLine src={BlueLineImg} />
              </Fade>
            </VerticalAlign>
            <OverlaySplash />
          </BackgroundImageStyled>
        </BackgroundStyled>
      )
    }}
  />
)

export default HomeHero
