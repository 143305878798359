import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import posed from 'react-pose';
import { Link } from 'gatsby'
import { getProductLink } from '../../services/product/link'
import { getCheckout } from "../../store/reducers"
import { connect } from "react-redux"
import { getLocalisedPrice } from "../../services/product/price"
import { isCartUpdating, isCartUpdated } from "../../store/reducers/index"
import { getOptionsData,getSelectedVariant } from "../../services/product/options"
import { addItemAction } from "../../store/actions/cart/index"

const ProductCardContainer = styled.div`
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-bottom: 20px;
    padding-top: 20px;
`;
const ProductImg = styled.img`
    max-width: 80%;
    height: auto;
    max-height: ${props => props.selected ? '350px' : '350px'};
    margin-left: auto;
    @media(max-width: 768px) {
        max-width: 450px;
        margin-left: auto;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        max-height: ${props => props.selected ? '350px' : '350px'};
    }
`;
const ProductTitle = styled.div`
    ${fonts.swissBlackExtended};
    font-size: 16px;
    letter-spacing: 1.41px;
    text-transform: capitalize;
    line-height: 18px;
    color: #222E3C;
    padding-top: 30px;
    text-transform: uppercase;
`;
const ProductPrice = styled.div`
    ${fonts.gilroyRegular};
    color: #222E3C;
    font-size: 15px;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 25px;
`;
const LinkStyled = styled(Link)`
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 2.77px;
    ${fonts.gilroyBold};
    text-transform: uppercase;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
`;

const ButtonStyled = styled.button`
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 2.77px;
    ${fonts.gilroyBold};
    text-transform: uppercase;
    text-decoration: none;
    outline: none;
    padding: none;
    background: transparent;
    border: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
`;
const LinkText = styled.div`
    cursor: pointer;
    height: 44px;
    width: 144px;
    border: 1px solid #9DDCE2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
    z-index: 1;
    background: white;
    color: #222E3C;
    transition: background .5s, color .5s;
    -webkit-transition: background .5s, color .5s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background: #222E3C;
        color: #9DDCE2;
    }
`;
const BorderStyled = styled.div`
    border-bottom: 5px solid #9DDCE2;
    border-right: 5px solid #9DDCE2;
    width: 141px;
    height: 43px;
    position: absolute;
    bottom: -5px;
    right: -5px;
`;
const HoverZoom = posed.div({
    hoverable: true,
    focusable: true,
    pressable: true,
    init: {
        scale: 1,
        boxShadow: '0px 0px 0px  rgba(171,218,225,0)'
    },
    hover: {
        scale: 1.1,
        boxShadow: '0px 5px 10px  rgba(171,218,225,0.5)'
    },
    press: {
        scale: 1.1,
        boxShadow: '0px 5px 10px  rgba(171,218,225,0.5)'
    },
    focus: {
        scale: 1.1,
        boxShadow: '0px 5px 10px  rgba(171,218,225,0.5)'
    }
});

const ProductCard = (props) => {
    const { product, isPlp } = props;
    const {title, priceRange: {minVariantPrice: {amount : price, currencyCode}}, images, handle, options, tags} = product;
    let hasOneVariant;
    let isSoldOut = tags.find((tag) => tag.toLowerCase().includes("soldout")) || false;


    if (isPlp) {
        hasOneVariant = product.variants.length === 1;
    }

    const addToCart = product => {
        const optionsData = getOptionsData(product)
        let variant = getSelectedVariant(product, optionsData)
        let variantImage

    
        if (product.images.length > 0) {
          const firstImage = product.images[0]
    
          if (firstImage.localFile) {
            variantImage = firstImage.localFile.url
          }
        }
    
        if (props.isUpdating) {
          return
        }
    
        props.addItemAction(variant, 1, variantImage)
      }

    return (
        <HoverZoom>
            <ProductCardContainer>
                {images[0] && <LinkStyled to={getProductLink(handle)}>
                    <ProductImg src={images[0].localFile.url} selected />
                </LinkStyled>}
                <ProductTitle>{title}</ProductTitle>
                <ProductPrice>{getLocalisedPrice(price,currencyCode, false)}</ProductPrice>
                {hasOneVariant? (
                    <ButtonStyled onClick={() => addToCart(product)} disabled={isSoldOut}><LinkText>{isSoldOut ? "Sold Out" : "Buy Now"}<BorderStyled /></LinkText></ButtonStyled>
                    ) :
                    <LinkStyled to={getProductLink(handle)} product={product}><LinkText>{isSoldOut ? "Sold Out" : "Buy Now"}<BorderStyled /></LinkText></LinkStyled>
                }
            </ProductCardContainer>
        </HoverZoom>
    )
}

const mapStateToProps = state => {
    return {
      cart: getCheckout(state),
      isAdding: isCartUpdating(state),
      isAdded: isCartUpdated(state),
    }
  }

 const mapDispatchToProps = {
    addItemAction,
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)
